@import 'src/styles/imports';


$button-color:        rgba(25, 27, 30, .8);
$active-button-color: rgba(25, 27, 30, .93);


.component {
  position: absolute;
  z-index: 10;
  right: 3em;
  bottom: 2em;
  display: flex;
  width: 0;
  margin-top: 1em;
  color: $color-white;
  flex-direction: column;
  transition: all $transition-duration ease-in-out;

  &.not-visible {
    opacity: 0;
    pointer-events: none;
  }

  @include desktop {
    top: .1em;
    left: 1.2em;
  }

  &.large-side-panel {
    left: 40em;
  }

  span {
    display: flex;
    box-shadow: $box-shadow;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    margin-bottom: .5em;
    background: $button-color;
    text-align: center;
    cursor: pointer;
    align-items: center;
    justify-content: center;

    svg,
    path {
      fill: $color-white;
    }

    &.active {
      background: $active-button-color;
    }
  }

  .filter {
    height: 1em;
    transform: rotate(90deg);
  }

  .list-view {
    height: 1.25em;
    margin-top: .075em;
    margin-left: .075em;
  }

  .loading-location {
    svg {
      width: 1.5em;
      height: 1.5em;
    }
  }

  .desktop-only  {
    display: none;

    @include desktop {
      display: flex;
    }
  }

  .mobile-only { // includes tablet
    display: flex;

    @include desktop {
      display: none;
    }
  }
}


.save-search-icon {
  height: 1em;
}

.compass-arrow {
  height: 1.25em;
}
