@import 'src/styles/imports';

.container {
  position: relative; // for top-bar's box shadow to show
  width: 100vw;
  height: 100%;
}

.top-bar {
  position: absolute;
  z-index: $z-index-map-header;
  top: 0;
  display: none;
  width: 100%;
  padding: 0 1em;
  background-color: $color-white;
  filter: drop-shadow(0 1px 2px $color-black-65);

  @include desktop {
    display: block;
  }
}

.filters {
  display: none;
  padding: 0 1em;

  @include not-mobile {
    display: block;
  }
}

.bottom-section {
  display: flex;
  flex-direction: row;

  @include desktop {
    padding-top: 83px; // make space for top-bar
  }
}

.pin {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: $color-sand;
}

.review-callout {
  box-shadow: 0 1px 2px 1px $color-black;
  width: 100px;
  height: 100px;
  border-radius: 2px;
  background-color: $color-white;
}

.circle-annotation {
  font-size: 11px;
  border: 2px solid $color-white;
  border-radius: 100px;
  background-color: $color-orange;
  color: $color-white;
  font-weight: 700;
  text-align: center;
  transition: all .2s ease-in-out;

  &.size-1 {
    @include pin-of-size(16, false);
  }

  &.size-2 {
    @include pin-of-size(20, false);
  }

  &.size-3 {
    @include pin-of-size(26, false);
  }

  &:hover {
    background-color: $color-grey-9;
  }
}

.side-panel {
  z-index: 10;
  overflow-y: scroll;
  width: 350px;
  height: 100%;
  padding: 1em;

  @include desktop {
    display: block;
    width: 20%;
  }
}

.loading-map {
  position: absolute;
  width: 100%;
  height: 100%;
  background: $color-map-background;
}
