@import '/src/styles/imports';

.component {
  position: absolute;
  z-index: $z-index-listing-popover;
  box-shadow: $box-shadow-spread;
  width: 22.5em;
  border-radius: 2px;
  background: $color-white;
}
