@import 'src/styles/imports';

.row {
  display: flex;
  align-items: center;
}

.listing-detail {
  font-size: .6875em;
  display: inline-flex;
  align-items: center;

  &:last-of-type {
    margin-right: 0;
  }

  .icon {
    display: none;
    margin-right: .3em;

    @include desktop {
      display: block;
    }
  }
}

.sign-in {
  font-size: .6875em;
  margin-top: .4em;
}

.slash {
  font-size: .25em;
  margin: 0 1.5em;
}
