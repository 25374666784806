@import 'src/styles/imports';

.component {
  font-size: .6875em;
  z-index: $z-index-panel;
  box-shadow: $box-shadow;
  border-radius: 6.5em;
  background-color: $color-orange;
  color: $color-white;
  font-weight: 700;
  text-align: center;
  filter: drop-shadow(0 1px 2px $color-black-65);
  transition: all .2s ease-in-out;

  &.size-1 {
    @include pin-of-size(18, false);
  }

  &.size-2 {
    @include pin-of-size(22, false)
  }

  &.size-3 {
    @include pin-of-size(26, false)
  }

  .nib {
    position: absolute;
    bottom: -7px;
    width: 0;
    height: 0;
    border-top: 7px solid $color-orange;
    border-right: 7px solid transparent;
    border-left: 7px solid transparent;
    transform: translateX(50%);
    transition: all .2s ease-in-out;
  }

  &.price-pin {
    position: relative;
    display: inline-flex;
    width: auto;
    height: auto;
    padding: .5em;
    border-radius: 0;
    white-space: nowrap;
    transform: translateX(-50%);
  }

  &:hover {
    background-color: $color-grey-9;

    .nib {
      border-top: 7px solid  $color-grey-9;
    }
  }

  &.active {
    background-color: $color-grey-9;

    .nib {
      border-top: 7px solid  $color-grey-9;
    }
  }
}

.visited {
  background-color: $color-visited-map-pin;

  .nib {
    border-top-color: $color-visited-map-pin;
  }
}
