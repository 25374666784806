@import 'src/styles/imports';

.remove-boundary {
  font-size: .875em;
  position: absolute;
  bottom: 6em;
  left: 50%;
  padding: .5em;
  border-radius: 50px;
  background-color: $color-black-65;
  color: $color-white;
  cursor: pointer;
  transform: translateX(-50%);

  @include desktop {
    bottom: 1em;
    padding: 1em;
  }
}

.map-loading {
  position: absolute;
  top: 1em;
  left: 50%;
  display: flex;
  height: 1em;
  padding: 1.25em;
  border-radius: $border-radius-medium;
  background-color: $color-black-40;
  color: $color-white;
  align-items: center;
  justify-content: space-between;
  transform: translateX(-50%);

  @include desktop {
    padding: 1.25em 3em;
  }

  .map-spinner {
    svg {
      width: 1.5em;
      height: 1.5em;
    }
  }
}

.map {
  position: relative;
  height: calc(100vh - 80px);
  flex: 1 1 auto; // Map will grow and take all available space

  @include desktop {
    height: calc(100vh - 163px);
  }
}


.slide-up {
  display: block;

  @include not-mobile {
    display: none;
  }
}
