@import '/src/styles/imports';

.component {
  display: flex;
  overflow-x: hidden;
  max-height: 20em;
  border-radius: 2px;
  background: $color-white-80;
  cursor: pointer;
  flex-direction: column;
  justify-content: space-between;
}

.top {
  display: grid;
  padding: 1em;
  justify-content: space-between;
  grid-template-columns: 1fr 3fr;
}

.filter-by-boundary {
  font-size: .75em;
  width: 100%;
  border-radius: 0;
}

.no-boundary {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1em 0;
  background-color: $color-grey-3;
}

.footer {
  font-size: .625em;
  padding: .5em;
  text-align: center;
}

.school-attributes {
  text-transform: capitalize;
}

.rating-section {
  padding-right: 1em;
  border-right: 1px solid $color-grey-9;
  text-align: center;
}

.rating {
  font-size: 2em;
  color: $color-blue;
}

.rating-range {
  overflow: hidden;
  white-space: nowrap;
}

.school-info {
  font-size: .875em;
  padding-left: 1em;
}

.school-name {
  overflow: hidden;
  max-width: 75%;
  font-weight: $font-weight-bold;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.eqao-credit {
  font-size: .625em;
  margin-bottom: 1em;
  color: $color-grey-5;
}
