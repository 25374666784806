
.component {
  display: grid;
  height: 100%;
  grid-gap: .5em;

  .loading-spinner {
    margin-top: auto;

    svg {
      width: 2em;
      height: 2em;
    }
  }

  .view-more {
    width: calc(100% - 1em);
    padding: 1em;
    margin: 1em .5em;
  }
}
