// sass-lint:disable no-important
@import 'src/styles/imports';

.component {
  position: relative;
  height: 106px;
  padding: 5px;
  border: 1px solid $color-grey-1;
  cursor: pointer;

  .image {
    position: absolute;
    top: 8px;
    overflow: hidden;
    width: 45%;
    height: 90px;
    border-radius: $border-radius-medium;
  }

  .right {
    position: absolute;
    right: 0;
    display: flex;
    width: 55%;
    height: 90%;
    padding-left: .75em;
    flex-direction: column;
    justify-content: space-evenly;
  }

  .wrapper {
    padding: .5em;
  }

  .price-time {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .blur-image {
    filter: blur(4px) !important; // Note: Blur on private listings
  }

  .street-address {
    font-size: .75em;
    overflow: hidden;
  }

  .text {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    color: $color-white;
    text-align: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
}

.date-added {
  display: none;
  margin-left: 0;

  @include desktop {
    display: block;
  }
}

.date-added-mobile {
  display: block;

  @include not-mobile {
    display: none;
  }
}

.favourite {
  font-size: 1.25em;
  display: none;

  @include not-mobile {
    display: block;
  }
}
