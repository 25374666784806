@import '/src/styles/imports';

.component {
  position: absolute;
  filter: drop-shadow(0 1px 2px $color-black-65);

  svg {
    width: 29px;
    height: 36px;
  }

  &:hover svg path:first-of-type { // For interior of school pin 
    fill: $color-grey-9;
  }

  &.exp-theme {
    svg {
      path {
        &:first-child {
          fill: $color-exp-secondary-dark-navy;
        }
      }
    }
  }
}
